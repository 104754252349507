





import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import tippy, { Placement } from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'

@Component
export default class TooltipButton extends Vue {
  private tooltipId = 'id' + this.$uuid.v4()

  /**
   * 表示内容
   *
   * https://atomiks.github.io/tippyjs/v6/all-props/#content
   */
  @Prop({ default: '' })
  content!: string

  /**
   * 表示位置
   *
   * ここに指定した箇所にtooltipを表示する
   * デフォルトは左下とする
   * https://atomiks.github.io/tippyjs/v6/all-props/#placement
   */
  @Prop({ default: 'left-end' })
  placement!: Placement

  /**
   * 表示トリガー
   *
   * ここに指定した操作方法で表示切り替えを行う
   * デフォルトはクリック表示
   * https://atomiks.github.io/tippyjs/v6/all-props/#trigger
   */
  @Prop({ default: 'click' })
  trigger!: 'mouseenter focus' | 'click' | 'focusin' | 'mouseenter click' | 'manual'

  private tippy?: any

  private hiddenTippy() {
    if (this.tippy && this.tippy.length) {
      this.tippy[0].hide()
    }
  }

  private mounted() {
    window.addEventListener('hidden-tippy', this.hiddenTippy)
    this.initTippy()
  }

  private unmounted() {
    window.removeEventListener('hidden-tippy', this.hiddenTippy)
  }

  private initTippy() {
    // 外部ライブラリのtippyを使用
    // その他必要に応じてオプションを追加する場合は公式を参照(https://atomiks.github.io/tippyjs/)
    this.tippy = tippy(`#${this.tooltipId}`, {
      content: this.content,
      placement: this.placement,
      trigger: this.trigger,
      theme: 'light-border',
      maxWidth: 'none',
    })
  }

  /**
   * contentに変更があった場合、再度初期化を行う
   */
  @Watch('content')
  onChangeContent() {
    this.initTippy()
  }
}
