















import { Component, Prop, Vue } from 'vue-property-decorator'
export type Stroke = {
  x: number
  y: number
}
const DRAW_LINE_WIDTH = 5
const ERASER_LINE_WIDTH = 15
@Component
export default class PenCanvas extends Vue {
  @Prop()
  name!: string
  private currentStroke: Stroke[] = []
  private strokes: Stroke[][] = []
  private isDrawable = false
  private lineWidth = DRAW_LINE_WIDTH
  private canvas: HTMLCanvasElement | null = null
  private context: CanvasRenderingContext2D | null = null
  private isDrag = false
  private penColor = '#333333'
  private alpha = 1
  private compositeOperation = 'source-over'

  private get refs(): any {
    return this.$refs
  }

  private mounted() {
    if (!Vue.prototype.$penCanvases) Vue.prototype.$penCanvases = {}
    Vue.prototype.$penCanvases[this.name] = this
    this.canvas = this.refs['penCanvas']
    if (!this.canvas) return
    this.context = this.canvas.getContext('2d')
  }

  private activateCanvas() {
    this.isDrawable = true
  }

  private deactivateCanvas() {
    this.isDrawable = false
  }

  /**
   * canvasのサイズ変更
   */
  private changeSize({ width = 0, height = 0 }: { width: number; height: number }): void {
    if (!this.canvas) return
    this.canvas.width = width
    this.canvas.height = height
    this.setCanvasContext()
  }

  /**
   * canvasのパラメータ設定
   */
  private setCanvasContext(): void {
    if (!this.context) return
    this.context.globalCompositeOperation = this.compositeOperation
    this.context.lineCap = 'round'
    this.context.lineJoin = 'round'
    this.context.lineWidth = this.lineWidth
    this.context.strokeStyle = this.penColor
    this.context.globalAlpha = this.alpha
  }

  /**
   * ペンの種類を設定
   */
  private changePenType({ colorCode, alpha = 1 }: { colorCode: string; alpha: number }): void {
    this.lineWidth = DRAW_LINE_WIDTH
    this.penColor = colorCode
    this.alpha = alpha
    this.compositeOperation = 'source-over'
    this.setCanvasContext()
  }

  /**
   * canvasに消しゴム設定
   */
  private setEraser(): void {
    this.lineWidth = ERASER_LINE_WIDTH
    this.compositeOperation = 'destination-out'
    this.alpha = 1
    this.setCanvasContext()
  }

  /**
   * 描画
   */
  private draw(e: any): void {
    e.preventDefault() // スクロール抑制
    if (!this.context || !this.isDrag) return

    const { x, y } = this.caliculatePosition(e)
    this.currentStroke.push({ x, y })
    this.context.lineTo(x, y)
    this.context.stroke()
  }

  /**
   * 描画開始
   */
  private dragStart(e: any): void {
    e.preventDefault() // スクロール抑制
    if (!this.context) return

    const { x, y } = this.caliculatePosition(e)
    this.currentStroke.push({ x, y })
    this.context.beginPath()
    this.context.lineTo(x, y)
    this.context.stroke()

    this.isDrag = true
  }

  /**
   * 描画終了
   */
  private dragEnd(): void {
    if (!this.context) return
    switch (this.lineWidth) {
      case DRAW_LINE_WIDTH: {
        if (this.currentStroke.length) {
          this.strokes = this.strokes.concat([this.currentStroke])
          this.currentStroke = []
        }
        break;
      }

      case ERASER_LINE_WIDTH: {
        if (this.currentStroke.length) {
          const newStrokes: Stroke[][] = []

          this.strokes.forEach((stroke) => {
            let isIntersect = false

            for (let i = 1; i < stroke.length; i++) {
              const xmn1 = Math.min(stroke[i].x, stroke[i - 1].x)
              const xmx1 = Math.max(stroke[i].x, stroke[i - 1].x)
              const ymn1 = Math.min(stroke[i].y, stroke[i - 1].y)
              const ymx1 = Math.max(stroke[i].y, stroke[i - 1].y)

              for (let j = 1; j < this.currentStroke.length; j++) {
                const xmn2 = Math.min(this.currentStroke[j].x, this.currentStroke[j - 1].x)
                const xmx2 = Math.max(this.currentStroke[j].x, this.currentStroke[j - 1].x)
                const ymn2 = Math.min(this.currentStroke[j].y, this.currentStroke[j - 1].y)
                const ymx2 = Math.max(this.currentStroke[j].y, this.currentStroke[j - 1].y)

                if (xmn1 <= xmx2 && ymn1 <= ymx2 && xmn2 <= xmx1 && ymn2 <= ymx1) {
                  isIntersect = true
                }
              }
            }

            // 交差しない場合は配列に格納
            if (!isIntersect) newStrokes.push(stroke)
          })

          this.currentStroke = []
          this.strokes = newStrokes
        }
        break
      }

    }
    this.context.closePath()
    this.isDrag = false
  }

  /**
   * 描画座標を取得
   */
  private caliculatePosition(e: any): any {
    // スマホの場合とで描画座標の取得方法が異なる
    if (!this.canvas) return
    let [x, y] = [e.offsetX, e.offsetY]
    // スマホ対応
    if (e.touches !== undefined) {
      if (!this.canvas) return
      const client = this.canvas.getBoundingClientRect()
      x = e.touches[0].pageX - client.left
      y = e.touches[0].pageY - (client.top + window.pageYOffset)
    }

    return { x, y }
  }

  private getCanvasAsImageDataType() {
    const imageData = this.context?.getImageData(0, 0, this.canvas?.width || 0, this.canvas?.height || 0)
    return imageData?.data
  }

  private drawCanvasFromImageData(imageData: Uint8ClampedArray) {
    const restoredImageData = new ImageData(imageData, this.canvas?.width || 0, this.canvas?.height || 0)

    this.context?.putImageData(restoredImageData, 0, 0)
  }

  private exportCanvasToFileImage(nameImage?: string) {
    const imageDataUrl = this.canvas?.toDataURL('image/png')

    // Chuyển đổi thành ArrayBuffer
    const byteString = atob(imageDataUrl?.split(',')?.[1] || '')
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uint8Array = new Uint8Array(arrayBuffer)
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i)
    }

    // Tạo Blob từ ArrayBuffer
    const blob = new Blob([uint8Array], { type: 'image/png' })

    // Tạo File từ Blob
    return new File([blob], `${nameImage}.png`, { type: 'image/png' })
  }

  private canvasToFile(nameImage?: string) {
    return new Promise((resolve, reject) => {
      this.canvas?.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Failed to create image blob'))
        } else {
          const file = new File([blob], nameImage || 'image.png', { type: 'image/png' })
          resolve(file)
        }
      }, 'image/png')
    })
  }

  private clearCanvas() {
    this.context?.clearRect(0, 0, this.canvas?.width || 0, this.canvas?.height || 0)
  }

  private checkCanvasIsEmpty() {
    const imageData = this.context?.getImageData(0, 0, this.canvas?.width || 0, this.canvas?.height || 0)
    const data = imageData?.data

    let isEmpty = true

    // Check if any non-transparent pixel is present
    for (let i = 3; i < (data?.length || 0); i += 4) {
      if (data?.[i] !== 0) {
        isEmpty = false
        break
      }
    }

    return isEmpty
  }

  private canvasToBase64() {
    const dataURL = this.canvas?.toDataURL()
    return dataURL
  }

  private drawImageFromBase64(
    base64String: string,
    options: {
      width: number
      height: number
    }
  ) {
    const img = new Image()
    const canvas = this
    img.src = base64String
    img.onload = function () {
      canvas.clearCanvas();
      canvas?.context?.drawImage(img, options.width, options.height)
    }
  }

  public getStroke() {
    return this.strokes
  }


  public clearStrokes() {
    this.strokes = []
    this.currentStroke = []
  }
}
